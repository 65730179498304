import { aiIcon, closeIcon, checkIcon, arrowRightIcon } from './svg';
export const __html = `
<section class="custom-style extended snakkis">
      <div class="card">
        <h2 class="snakkis-title">
            <span class="greeting">
                Hei <span class="username"></span> 👋
            </span>
            Dette har skjedd siden sist du var innom:
        </h2>
        <menu class="story-selection" role="tablist">
        </menu>
        <div class="story-list">
        </div>
        <ul class="story-list-items" hidden></ul>
        <a href="https://iter.ly/tvgm9" target="_blank" class="snakkis-feedback-link">Gi tilbakemelding ${arrowRightIcon}</a>
        <button class="ai-disclaimer">
            <span class="ai-disclaimer-text">Chatboten er laget med kunstig intelligens og kan gjøre feil.</span>
            ${aiIcon}
            ${closeIcon}
        </button>
      </div>
        <div hidden class="snakkis-disclaimer">
        <p>
            «Siste nytt» er et eksperiment hvor VG tester ut hvordan kunstig intelligens kan gi deg en personlig oppsummering av nyhetsbildet siden sist du var innom. VG bruker OpenAIs ChatGPT 4o-modell for å lage disse sammendragene.
        </p>
        <p>
            VG avgjør hvilke hendelser som inkluderes i oppsummeringen og følger med på tilbakemeldingene, slik at de kan forbedres og eventuelle feil rettes.
        </p>
        <p>
            Du er blant de utvalgte brukerne som får teste denne funksjonen. <a href="https://iter.ly/tvgm9" target="_blank">Del gjerne din tilbakemelding her</a>
            </p>
        </div>

      <button class="snakkis-show-more" hidden>Vis mer</button>

      <template class="story">
        <article class="story" role="tabpanel" aria-hidden="true" aria-live="polite">
          <div class="summary"></div>
            <div class="source"><a href="">Les mer</a></div>
            <!--<div class="info"><b>3 artikler</b> - 4 timer siden</div>-->
            <ol class="chat-messages"></ol>
            <div class="questions"></div>
          </div>
        </article>
      </template>

      <template class="story-list-item">
        <li class="story-list-item" style="line-height: 26px">
            <h3 class="story-list-item-title"></h3>
           <p></p>
            <a href="#" class="story-list-item-link">Les mer</a>
        </li>
      </template>

      <template class="question">
        <button class="question" disabled>
          <span class="question-text"></span>
        </button>
      </template>

    <template class="status-message">
        <div class="status-message">
            <span class="status-message-text"></span>
            <button hidden class="status-message-button">Vil du bli oppdatert på <span class="status-message-story"></span>?</button>
        </div>
    </template>

    <template class="check-icon">
        ${checkIcon}
    </template>
</section>`;
